import * as React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Sidebar from '../components/sidebar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
// markdown記法がHTMLに変換された要素は、styled-components を使用して直接修正する
import styled from 'styled-components';
// コメント機能を追加するプラグイン
import { Disqus } from 'gatsby-plugin-disqus';
// Markdownファイルから変換されたHTMLに手を加える
import rehypeReact from 'rehype-react';
// gatsby-plugin-image追加
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// パンくずリストコンポーネント
import BreadCrumbList from '../components/breadcrumb-list';

// リンクカードコンポーネント
import LinkCard from '../components/link-card';

// 目次コンポーネント
import TableOfContent from '../components/table-of-content';

// iframelyのcdnを読み込むコンポーネント
import Iframely from '../components/iframely';

// 記事内のGIF画像をリサイズするコンポーネント
import ApplyStyleToGifImages from '../components/app-style-gif-images';

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    // Markdownファイル内で card というタグをリンクカードに変換する
    card: LinkCard,
  },
}).Compiler;

const BlogPostTemplate = ({ pageContext, data, location }) => {
  const sidebarTags = pageContext.tags.sort();
  const post = data.markdownRemark;
  const siteUrl = data.site.siteMetadata.siteUrl;
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const { previous, next } = data;
  // 追記↓
  const eyeCatchImg = data.allFile.edges[0].node.childImageSharp;

  // 追記↓
  const { tags } = data.markdownRemark.frontmatter;

  const ogpImg = data.allFile.edges[0].node.publicURL;

  let disqusConfig = {
    url: `${siteUrl + location.pathname}`,
    identifier: post.id,
    title: post.title,
  };

  return (
    <Layout
      location={location}
      title={siteTitle}
    >
      {/* GIF画像にスタイルを適用するコンポーネント */}
      {/* ビジュアルを持たないコンポーネントなので、配置はどこでも良い */}
      <ApplyStyleToGifImages />

      <Iframely />
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        img={ogpImg}
        location={location}
      />
      <BlogPostContainer>
        <div className="p-blog-post__layout py-md-3">
          <article
            className="blog-post"
            itemScope
            itemType="http://schema.org/Article"
          >
            <BreadCrumbList
              parent="blogs"
              location={location}
              title={post.frontmatter.title}
            />
            <ArticleHeader>
              <h1 itemProp="headline">{post.frontmatter.title}</h1>
              <FontAwesomeIcon icon={faClock} />
              <time>{post.frontmatter.date}</time>
              {post.frontmatter.modifieddate ? (
                <span>
                  <FontAwesomeIcon icon={faClockRotateLeft} />
                  <time>{post.frontmatter.modifieddate}</time>
                </span>
              ) : (
                ''
              )}

              <GatsbyImage
                image={getImage(eyeCatchImg)}
                alt={post.frontmatter.title}
                key={post.frontmatter.title}
                style={{ marginBottom: `8px` }}
              />

              {/* タグ */}
              <TagArea>
                {tags.map((tag, index) => {
                  return (
                    <Link
                      key={`tag${index}`}
                      to={`/tags/${tag}/`}
                    >
                      {tag}
                    </Link>
                  );
                })}
              </TagArea>
            </ArticleHeader>

            <TableOfContent data={data.markdownRemark.tableOfContents} />
            {/* ブログ本文が出力されるエリア */}
            <SectionWrapper itemProp="articleBody">{renderAst(post.htmlAst)}</SectionWrapper>
            <hr />
          </article>
          <Disqus config={disqusConfig} />
          <nav className="blog-post-nav">
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {next && (
                  <Link
                    to={next.fields.slug}
                    rel="next"
                  >
                    ← {next.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {previous && (
                  <Link
                    to={previous.fields.slug}
                    rel="prev"
                  >
                    {previous.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </div>
        <Sidebar sidebarTags={sidebarTags} />
      </BlogPostContainer>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
    # ↓追加
    $hero: String
  ) {
    site {
      siteMetadata {
        title
        # ↓コメント機能追加時に追記
        siteUrl
      }
    }
    allFile(
      filter: {
        # 画像パスが$heroと一緒のファイルを探す
        relativePath: { eq: $hero }
        # 画像を格納してある場所を指定（gatsby-config.jsで指定した名）
        sourceInstanceName: { eq: "images" }
      }
    ) {
      edges {
        node {
          publicURL
          relativePath
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1000, formats: [WEBP], placeholder: BLURRED, quality: 70, sizes: "(max-width: 600px) 100vw, 1000px")
          }
        }
      }
    }
    # ↑追加

    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      # htmlからhtmlAstに書き換える
      htmlAst
      # 目次出力用（見出し3まで表示する）
      tableOfContents(maxDepth: 3)
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        modifieddate(formatString: "YYYY-MM-DD")
        description
        cate
        tags
      }
    }

    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;

const BlogPostContainer = styled.div`
  display: block;
  width: 100%;

  img {
    width: 100%;
  }

  ol {
    & li {
      word-break: break-all;
    }
  }

  @media (min-width: 1200px) {
    display: flex;
  }

  .p-blog-post__layout {
    @media (min-width: 1200px) {
      margin-left: 8.3%;
      width: 66.6%;
    }
  }
`;

const TagArea = styled.div`
  display: flex;
  font-weight: 700;

  & p {
    margin-left: 1rem;
    &:first-child {
      margin-left: 0px;
    }
  }

  & > a {
    display: inline-block;
    padding: 4px 12px;
    background-color: #0a0b37;
    color: #ffffff;
    font-size: 12px;
    margin-right: 8px;
    transition: all 0.3s;

    &:last-child {
      margin-right: 0px;
    }

    &:hover {
      color: #ffffff;
      opacity: 0.6;
    }
  }
`;

const SectionWrapper = styled.section`
  .gatsby-highlight {
    margin: 0px -16px 1.5rem -16px;
    @media (min-width: 1200px) {
      margin: 0px 0px 1.5rem 0px;
    }
  }

  .token.prefix {
    user-select: none;
  }

  blockquote {
    padding: 2.3em 7%;
    margin: 1.5em 0 1.5em;
    position: relative;
    border: 2px solid rgba(103, 103, 103, 0.4);
    border-radius: 7px;
    opacity: 0.9;
    white-space: break-spaces;
    word-break: break-all;

    &::before {
      font-family: serif;
      position: absolute;
      width: 1em;
      height: 1em;
      line-height: 1.5;
      display: block;
      padding: 0;
      text-align: center;
      font-size: 420%;
      opacity: 0.5;
    }
    &::after {
      font-family: serif;
      position: absolute;
      width: 1em;
      height: 1em;
      line-height: 1.5;
      display: block;
      padding: 0;
      text-align: center;
      font-size: 420%;
      opacity: 0.5;
    }

    & > p {
      margin-bottom: 0;
    }
  }

  blockquote > :last-child p {
    margin-bottom: var(--spacing-0);
  }

  blockquote > ul,
  blockquote > ol {
    list-style-position: inside;
  }

  h1 {
    font-size: 1.3rem;
    border-left: 10px solid #ff6768;
    border-bottom: 1px #999 dotted;
    margin-bottom: 24px;
    padding: 10px 20px 10px 20px;
    word-break: break-all;

    @media (min-width: 1200px) {
      font-size: 2rem;
      font-size: 1.6rem;
    }
  }

  h2 {
    position: relative;
    border: none;
    font-size: 1.2em;
    padding: 0.9em 1em;
    margin-top: 2.1em;
    margin-bottom: 2em;
    background: #ff6768;
    color: #ffffff;
    -webkit-box-shadow: 0 1px 5px rgb(0 0 0 / 3%);
    box-shadow: 0 1px 5px rgb(0 0 0 / 3%);

    @media (min-width: 1200px) {
      font-size: 1.3em;
    }

    &:after {
      content: '';
      position: absolute;
      border-top: 13px solid #ff6768;
      border-right: 9px solid transparent;
      border-left: 9px solid transparent;
      bottom: -11px;
      left: 5%;
    }

    & a {
      color: white;
      text-decoration-line: underline;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  h3 {
    padding: 8px 8px 8px 12px;
    font-size: 1.25rem;
    border-top: double 6px #ff6768;
    border-bottom: double 6px #ff6768;
    margin-bottom: 1.3rem;
  }

  .gatsby-code-title {
    background-color: rgb(116, 109, 101);
    color: white;
    padding: 6px 12px;
    font-size: 13px;
    line-height: 1;
    font-weight: 700;
    display: table;
    border-radius: 4px 4px 0 0;
  }

  .gatsby-code-title span {
    display: inline;
    position: relative;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    color: #eee;
    border-top-left-radius: 0.3em;
    border-bottom-right-radius: 0.3em;
    padding: 3px;
    top: 1px;
  }

  pre[class*='language-'] {
    padding: 1em;
    margin: 0px;
    overflow: auto;
    border-radius: 0;
  }

  .iframely-embed {
    margin-bottom: 24px;
    transition: all 0.3s ease 0s;

    &:hover {
      box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
      transform: translateY(-4px);
    }
  }

  .language-text {
    white-space: break-spaces;
    word-break: break-all;
  }

  p {
    margin-bottom: 24px;
    font-size: 0.9rem;

    @media (min-width: 1200px) {
      font-size: 1rem;
    }

    & > a {
      word-break: break-all;
    }
  }

  ul {
    padding-left: 8px;
    list-style: none;

    & p {
      margin: 0px;
    }

    & li:first-child {
      margin-top: 3px;
    }

    & li:last-child {
      margin-bottom: 18px;
    }

    & li {
      list-style: none;
      position: relative;
      padding-left: 18px;
      margin: 14px 0;

      &::before {
        display: block;
        position: absolute;
        left: 2px;
        top: 8px;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 5px;
        background-color: #000;
      }

      & li {
        &::before {
          display: block;
          position: absolute;
          left: 2px;
          top: 8px;
          content: '';
          width: 6px;
          height: 6px;
          border-radius: 5px;
          background-color: #fff;
          border: solid 1px;
        }
      }
    }
  }

  // テーブルのレイアウト
  table {
    border-collapse: collapse;
    margin-bottom: 24px;
  }

  table tr {
    background: #ffffff;
  }

  table th {
    font-weight: 700;
    background-color: #f2f2f2;
  }

  table th,
  table td {
    color: #000;
    border: solid 1px #ddd;
    padding: 10px;
  }

  // strongタグに黄色の蛍光ペンマーカーのスタイルを追加する
  strong {
    background: linear-gradient(transparent 60%, #ff6 60%);
  }

  // 画像に枠線を追加して本文との境目を視覚的に分かりやすくする
  .gatsby-resp-image-wrapper {
    border: solid 1px #000;
  }
`;

const ArticleHeader = styled.header`
  margin-bottom: 2.3rem;
  & time {
    text-align: left;
    margin-left: 5px;
  }

  & span {
    margin-left: 16px;
  }

  & h1 {
    font-size: 1.6rem;
    margin-bottom: 1.1rem;

    @media (min-width: 1200px) {
      font-size: 2rem;
    }
  }

  & > ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;
